import React from 'react';

import { graphql } from 'gatsby';
import Lottie from 'react-lottie';

import * as animationData from 'data/404_lottie.json';

import { Button } from 'components/button';

const Page404 = (props) => {
  const {
    data: {
      page: { title },
    },
  } = props;

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <main>
      <section className="container container--xsmall container--404">
        <Lottie options={defaultOptions} />
        <Button>Voltar ao início</Button>
      </section>
    </main>
  );
};

export const query = graphql`
  query Page404($id: String) {
    page: wpPage(id: { eq: $id }) {
      title
      id
    }
  }
`;

export default Page404;
